import Swiper, { Navigation } from "swiper";
import ScrollReveal from "scrollreveal";
import "npm:swiper/swiper.min.css";
import "npm:swiper/modules/navigation/navigation.min.css";

const sr = ScrollReveal();

const initAnimation = () => {
  const aboutListItems = document.querySelectorAll(".about__list > li");
  aboutListItems.forEach((node, index) => {
    sr.reveal(node, { delay: index * 100 });
  });

  const describeCells = document.querySelectorAll(".describe-grid-cell");
  describeCells.forEach((node, index) => {
    sr.reveal(node, { delay: index * 100 });
  });

  const programItems = document.querySelectorAll(".program-item");
  console.log(programItems);
  programItems.forEach((node, index) => {
    sr.reveal(node, { origin: "left", distance: "70%" });
  });

  const howCells = document.querySelectorAll(".how-grid-cell");
  howCells.forEach((node, index) => {
    sr.reveal(node, { delay: index * 100 });
  });
};

const initSwiper = () => {
  const swiper = new Swiper(".speakers__swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 15,
    breakpoints: {
      760: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1320: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },

    loop: true,

    navigation: {
      nextEl: ".speaker__swiper-button-next",
      prevEl: ".speaker__swiper-button-prev",
    },
  });

  const expertsSwiper = new Swiper(".experts__swiper", {
    modules: [Navigation],
    slidesPerView: 1,
    loop: true,

    navigation: {
      nextEl: ".experts__swiper-button-next",
      prevEl: ".experts__swiper-button-prev",
    },
  });
};


const initRegisterModal = () => {
  const registerModal = document.querySelector('.leadforms-modal')
  const modalBackdrop = document.querySelector('.leadforms-form__backdrop');
  const registerButtons = document.querySelectorAll('.register-button');
  const closeButton = document.querySelector('.leadforms-form__close');

  Array.from(registerButtons).forEach((button) => {
    button.addEventListener('click', () => {
      registerModal.classList.toggle('leadforms-modal--disabled');
    })
  });

  modalBackdrop.addEventListener('click', () => {
    registerModal.classList.add('leadforms-modal--disabled');
  });

  closeButton.addEventListener('click', () => {
    registerModal.classList.add('leadforms-modal--disabled');
  });
}

document.addEventListener("DOMContentLoaded", () => {
  initAnimation();
  initSwiper();
  initRegisterModal();

  let isVisible = false;
  const button = document.getElementById("hidden-program-action");


  button.addEventListener("click", () => {
    const classList = document.getElementById("hidden-program").classList;

    if (isVisible) {
      classList.remove("program-hidden-block_visible");
      button.textContent = "Полная программа";
      // scrollToElement(button)
      button.scrollIntoView()
    } else {
      classList.add("program-hidden-block_visible");
      button.textContent = "Свернуть программу";
    }
    isVisible = !isVisible;
  });
});

